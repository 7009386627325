<template>
  <div>
    <b-modal
      v-model="isModalShow"
      :title="productId ? 'Update Product' : 'Create Product'"
      hide-footer
      size="lg"
      centered
      modal-class="modal-primary"
      @close="cancel"
      @hide="onHide"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            name="product_key"
            v-slot="validationContext"
            :rules="{ required: true, regex: /^[ ]*[A-Z]+(_?((?<=_)[A-Z]|[A-Z]))*[ ]*$/ }"
          >
            <b-form-group label="Product Key" label-for="product_key">
              <b-form-input
                v-model="dataModel.product_key"
                id="product_key"
                name="product_key"
                placeholder="PRODUCT_KEY"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="permission-keys"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group class="permission-key" label="Permission Keys" label-for="product_key">
              <b-button
                variant="btn-outline"
                class="btn-add-option"
                size="sm"
                @click="openAddOption"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="20"
                />
              </b-button>
              <multiselect
                v-model="dataModel.permission_keys"
                :options="productPermissions"
                :multiple="true"
                :searchable="true"
                show-no-results
                id="product_key"
                name="product_key"
                placeholder="PERMISSION KEYS"
              />
            </b-form-group>
            <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
          <div class="d-flex justify-content-between my-2 mt-4">
            <b-button variant="danger" @click="cancel">Cancel</b-button>
            <b-button variant="primary" @click="update">{{ productId ? 'Update' : 'Create' }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      v-model="isShowAddOption"
      title="Add Permission Key"
      hide-footer
      size="sm"
      centered
      modal-class="modal-primary"
      @hide="closeAddOption"
    >
      <validation-observer
        ref="formAddOption"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(addOption)">
          <validation-provider
            name="option"
            v-slot="validationContext"
            :rules="{ required: true, regex: /^[ ]*[A-Z]+(_?((?<=_)[A-Z]|[A-Z]))*[ ]*$/ }"
          >
            <b-form-group label="Product Key" label-for="option">
              <b-form-input
                v-model="permissionKey"
                id="option"
                placeholder="PERMISSION_KEY"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="text-center my-2">
            <b-button variant="primary" type="submit">Add</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service"
import Multiselect from 'vue-multiselect'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },

  props: {
    productPermissions: { type: Array, required: true },
  },

  data() {
    return {
      dataModel: {},
      isModalShow: false,
      productId: '',
      isShowAddOption: false,
      permissionKey: '',
    }
  },

  methods: {
    async getProductDetail(id) {
      const response = await service.get({ id })
      this.dataModel = response
    },

    openModal(id) {
      if (id) {
        this.productId = id
        this.getProductDetail(id)
      }
      this.isModalShow = true
    },

    cancel() {
      this.isModalShow = false
      setTimeout(() => {
        this.permissionKey = ''
        this.dataModel = {}
        this.productId = ''
      }, 300);
    },

    async update() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        if (this.productId) {
          const data = {
            _id: this.dataModel._id,
            product_key: this.dataModel.product_key.trim(),
            permission_keys: this.dataModel.permission_keys
          }
          const res = await service.update({
            data: JSON.stringify(data)
          })
          if (res.data && res.data.type === 'DATA') {
            this.$store.dispatch('pushSuccessNotify', { text: 'Update product successfully!' })
          }
        } else {
          const res = await service.create(this.dataModel)
          if (res.data && res.data.type === 'DATA') {
            this.$store.dispatch('pushSuccessNotify', { text: 'Create product successfully!' })
          }
        }
        this.$emit("getList")
        this.cancel()
      }
    },

    onHide(event) {
      if (event.trigger === 'backdrop') {
        this.cancel()
      }
    },

    async addOption() {
      let validation = await this.$refs.formAddOption.validate();
      if (validation) {
        const res = await service.createPermission({ permission_key: this.permissionKey.trim() })
        if (res.data && res.data.type === 'DATA') {
          this.$store.dispatch('pushSuccessNotify', { text: 'Create permission key successfully!' })
        }
        this.$emit('getListPermission')
        this.closeAddOption()
      }
    },

    openAddOption() {
      this.isShowAddOption = true
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    closeAddOption() {
      this.isShowAddOption = false
      setTimeout(() => {
        this.permissionKey = ''
      }, 300);
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.btn-add-option {
  position: absolute;
  top: -10px;
  left: 100px !important;
  color: green;
}
.permission-key {
  position: relative;
}
</style>
