<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Product Management" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Product Management</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <b-button variant="primary" @click="create">
                <b-spinner v-if="isLoading" small class="mr-50" />
                <feather-icon v-else icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Add New</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-card>
      <product-table
        :products="products"
        :product-permissions="productPermissions"
        @getList="getList"
        @edit="edit"
      />
    </b-card>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
    <product-modal
      :product-permissions="productPermissions"
      ref="productModal"
      @getListPermission="getListPermission"
      @getList="getList"
    />
  </div>
</template>

<script>
import service from "../service";
import ProductTable from "./_components/ProductTable.vue";
import ProductModal from "./_components/ProductModal.vue";

export default {
  components: {
    ProductTable,
    ProductModal,
  },

  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      products: [],
      productPermissions: [],
      editLanguage: null,
      isLoading: false,
    };
  },

  created() {
    this.getList();
    this.getListPermission();
  },

  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        this.products = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },

    async getListPermission() {
      let response = await service.getListPermission();
      if (response.data.type === "DATA") {
        this.productPermissions = response.data.data;
      }
    },

    create() {
      this.isLoading = true;
      this.$refs.productModal.openModal();
      this.isLoading = false;
    },

    edit(id) {
      this.isLoading = true;
      this.$refs.productModal.openModal(id);
      this.isLoading = false;
    }
  },
};
</script>
