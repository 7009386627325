import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/product', payload)
    return response || null
  },
  
  async update(payload) {
    let response = await api.put('/admin/product', payload)
    return response
  },

  async delete(payload) {
    const response = await api.delete('/admin/product', {
      data: payload,
    })
    return response
  },

  async getList(params) {
    let response = await api.get('/admin/product', {
      params,
    })
    return response
  },

  async getListPermission() {
    let response = await api.get('/admin/product/permissions')
    return response
  },

  async get(params) {
    let response = await api.get('/admin/product/detail', {
      params,
    })
    return response.data.data.document || null
  },

  async createPermission(payload) {
    const response = await api.post('/admin/product/permissions', payload)
    return response || null
  },
}
